import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Table from "@/views/Table";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/bios',
    name: 'BIOS',
    component: () => import('../views/BIOS.vue')
  },
  {
    path: '/jiaochen',
    name: 'JiaochenList',
    component: () => import('../views/JiaochenList.vue')
  },
  {
    path: '/jiaochen/:article',
    name: 'Jiaochen',
    props: true,
    component: () => import('../views/Jiaochen.vue')
  },
  {
    path: '/table/:table',
    component: Table,
    props: true
  },
  {
    path: '/plate/:plate_name',
    name: 'Plate',
    props: true,
    component: () => import('../views/Plate.vue')
  },
  {
    path:'*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
