<template>
  <v-container>
    <div id="nav">
      <keep-alive>
        <Nav></Nav>
      </keep-alive>
    </div>
    <v-row class="justify-center">
      <v-col cols="12" class="text-left">
        <v-card class="d-flex justify-start" flat>
          <v-card flat>
            <h1 class="headline text-h4 font-weight-bold">
              {{ data.name }}
            </h1>
          </v-card>
        </v-card>
        <v-card class="d-flex justify-start" flat>
          <v-card class="pa-2" flat v-show="data.version">
            <h1 class="text-subtitle-1 font-weight-medium">资源版本号：{{ data.version }}</h1>
          </v-card>
          <v-card flat class="pa-2">
            <h1 class="text-subtitle-1 font-weight-medium">数量：{{ data.content.length }}</h1>
          </v-card>
          <v-card flat class="pa-2" v-show="data.size">
            <h1 class="text-subtitle-1 font-weight-medium">数据总容量：{{ data.size }}</h1>
          </v-card>
        </v-card>
        <v-card class="pa-2" flat v-show="data.comment">
          <p class="text-subtitle-1 font-weight-medium">{{ data.comment }}</p>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="7" v-if="data.download">
        <v-card
            class="mx-auto"
        >
          <v-card-actions>
            <v-btn
                text
                block
                @click="show = !show"
            >
              <h3 class="font-weight-black">下载链接</h3>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>
              <v-card-text>
                <p
                    v-for="i in data.download"
                    :key="i"
                >
                  {{ i.comment }}<a :href="i.url" target="_blank">点击下载</a>
                </p>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <ListTable :plate="data" :tablePage="1"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListTable from '@/components/ListTable.vue'
import Nav from "@/components/Nav";
import router from "@/router";

export default {
  name: "Table",
  data: () => ({
    data: '',
    errored: false,
    show: false
  }),
  components: {
    ListTable,
    Nav
  },
  methods: {
    fetchData() {
      this.axios
          .get('https://data.yang2000ling.com/data/table/' + this.$route.params.table + '.json')
          .then(response => (this.data = response.data))
          .catch(error => {
                console.log(error)
                this.errored = true
                router.push('/')
              }
          )
    }

  },
  mounted() {
    this.fetchData()
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    '$route': 'fetchData'
  }
}
</script>

<style scoped>

</style>