<template>
  <div>
    <keep-alive>
      <v-navigation-drawer v-model="drawer" app width="400">
        <v-card class="mx-auto" width="400">
          <v-list nav>
            <v-subheader @click.stop="drawer = !drawer" class="justify-center"><span style="color: black"><h1>ROM猎人</h1></span>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item to="/">
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>主页</v-list-item-title>
              </v-list-item>
              <v-list-item href="https://t.me/yang2000ling">
                <v-list-item-icon>
                  <v-icon>mdi-podcast</v-icon>
                </v-list-item-icon>
                <v-list-item-title>TG频道</v-list-item-title>
              </v-list-item>
              <v-list-item to="/table/log">
                <v-list-item-icon>
                  <v-icon>mdi-notebook-edit-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>更新日志</v-list-item-title>
              </v-list-item>
              <v-list-group prepend-icon="mdi-gamepad-variant">
                <template v-slot:activator>
                  <v-list-item-title>家用机游戏平台</v-list-item-title>
                </template>
                <v-list-item
                    v-for="child in data.family.list"
                    :key="child"
                    :to="child.uri"
                >
                  <v-list-item-icon>
                    <v-icon></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title v-text="child.name"></v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-group prepend-icon="mdi-nintendo-game-boy">
                <template v-slot:activator>
                  <v-list-item-title>掌机机游戏平台</v-list-item-title>
                </template>
                <v-list-item
                    v-for="child in data.portable.list"
                    :key="child"
                    :to="child.uri"
                >
                  <v-list-item-icon>
                    <v-icon></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title v-text="child.name"></v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-item to="/bios">
                <v-list-item-icon>
                  <v-icon>mdi-folder-download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>各平台BIOS下载</v-list-item-title>
              </v-list-item>
              <v-list-item to="/jiaochen">
                <v-list-item-icon>
                  <v-icon>mdi-book-open-page-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>模拟器与安装教程</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-navigation-drawer>
    </keep-alive>
    <v-app-bar dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-show="!drawer"><a href="/"><span style="color: white"><h3>ROM猎人</h3></span></a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-center" v-show="navText">
        <v-btn text to="/table/log">
          更新日志
        </v-btn>
        <v-btn text to="/bios">
          各平台BIOS下载
        </v-btn>
        <v-btn text @click.stop="drawer = !drawer">
          各平台游戏目录
        </v-btn>
        <v-btn text to="/jiaochen">
          模拟器与安装教程
        </v-btn>
        <v-btn text href="https://t.me/yang2000ling">
          TG频道
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>


import router from "@/router";

export default {
  name: "Nav",
  props: {drawer: Boolean},
  data: () => ({
    data: '',
    drawer: false
  }),
  mounted() {
    this.fetchData()
  },
  computed: {
    navText() {
      return this.$vuetify.breakpoint.width > 700;
    }
  },
  methods: {
    fetchData() {
      this.axios
          .get('https://data.yang2000ling.com/data/nav.json')
          .then(response => (this.data = response.data))
          .catch(error => {
                console.log(error)
                this.errored = true
                router.push('/')
              }
          )
    }

  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>