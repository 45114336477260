<template>
  <v-card>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="搜索本目录"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="plate.header"
        :items="plate.content"
        :page.sync="tablePage"
        :search="search"
        :footer-props="tableFooter"
    >
      <template v-slot:item.img="{ item }">
        <v-card max-width="200" class="align-content-center">
          <v-img :src="item.img" max-width="200" max-height="250" contain :lazy-src="lazy" v-on:error="item.img=lazy"></v-img>
        </v-card>
      </template>
      <template v-slot:item.other="{ item }">
        <p v-show="item.language">语言：{{ item.language }}</p>
        <p v-show="item.region">区域：{{ item.region }}</p>
        <p v-show="item.genre">类别：{{ item.genre }}</p>
        <p v-show="item.developer">开发商：{{ item.developer }}</p>
        <p v-show="item.publisher">发行商：{{ item.publisher }}</p>
      </template>
      <template v-slot:no-results>
        <p>未查找到相关内容.</p>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "ListTable",
  props: ['plate', 'tablePage'],
  data: function () {
    return {
      lazy:"https://data.yang2000ling.com/img/etc/lazy.jpg",
      search: '',
      tableFooter: {
        showFirstLastPage: true,
        itemsPerPageAllText: "全部",
        itemsPerPageText: '每页行数',
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageOptions: [30]
      }
    }
  }
}
</script>

<style scoped>

</style>