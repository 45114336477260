<template>
  <v-container>
    <div id="nav">
      <Nav :drawer="drawer"/>
    </div>
    <div class="d-flex flex-column justify-space-between align-center">
      <v-img contain :max-width="height" src="../assets/cat_big.jpg"></v-img>
      <h1 class="v-heading font-weight-medium text-h4 text-sm-h3">欢迎访问ROM猎人</h1>
      <p class="mx-auto text-body-1" style="max-width: 600px;">本站为个人工作之余，打发闲暇时间的作品，主要目的是为了给大家提供各个平台游戏资源和模拟器教程，方便大家收集游玩。
        所有资源均已测包，请大家放心使用。解压密码1：yang2000ling 解压密码2：yang2000ling.com 解压密码3：yang2000ling.cn", 推荐用WINRAR最新版本进行解压，如下载两次均解压失败，请电邮直接联系站长。</p>
      <v-row align="center">
        <v-col
            cols="12"
            sm="6"
        >
          <v-btn
              large
              color="secondary"
              dark
              @click.stop="drawer = !drawer"
          >
            开始使用吧！
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Nav from "@/components/Nav";

export default {
  name: "Index",
  data: () => ({
    drawer:false
  }),
  components: {
    Nav
  },
  computed: {
    height: function () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 250
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return 300
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return 350
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return 400
      } else if (this.$vuetify.breakpoint.name === 'xl') {
        return 450
      } else return 500
    },
  },
}
</script>

<style scoped>

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
</style>